import React, { useState, useEffect } from "react";
import "../assets/css/enterQuery.css";
import Header from "./common/Header";
import { useNavigate } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "axios";
import {
  Response,
  Operation,
  InvalidImportation,
  InvalidImportationOrigin,
  InvalidImportationDestiny,
  MoreDetailsOrigin,
  MoreDetailsDestiny,
  COUNTRIES,
  VALIDCOUNTRIES,
} from "./common/constants/Constans";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";
import { OK_STATUS } from "./common/constants/ConstantsNumbers";

import Loader from "./common/Loader";

export default function EnterQuery({
  mainOrigin,
  mainDestiny,
  mainOption,
  mainDate,
  onOption,
  onOrigin,
  onDestiny,
  user,
  identify,
  name,
  onDoor,
}) {
  const apiEnterQuery1 = process.env.REACT_APP_API_EQ1;
  const apiEnterQuery2 = process.env.REACT_APP_API_EQ2;
  const key = process.env.REACT_APP_API_KEY;
  var historyObj = window.history;
  let navigate = useNavigate();
  const AnyReactComponent = ({ text }) => <div className="pin_map">{text}</div>;
  const [messageModal, setMessage] = useState("");

  //date-----------------------------------------------------------------------------------------------------------------------------------
  const [formattedDate, setFormattedDate] = useState("");

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  let [originLatLon, setOriginLatLon] = useState("");
  let [destinyLatLon, setDestinyLatLon] = useState("");

  useEffect(() => {
    const newDate = new Date();
    let auxformatDate = `${newDate.getDate()}/${
      newDate.getMonth() + 1
    }/${newDate.getFullYear()} ${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;
    setFormattedDate(auxformatDate);

    if (mainOrigin !== "" && mainDestiny !== "") {
      geocodeByAddress(mainOrigin)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setOriginLatLon(latLng);
        })
        .catch((error) => {
          console.error("Error", error);
        });

      geocodeByAddress(mainDestiny)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setDestinyLatLon(latLng);
        })
        .catch((error) => {
          console.error("Error", error);
        });
    }
  }, [mainOrigin, mainDestiny]);

  //operation--------------------------------------------------------------------------------------------------
  let [operation, setOperation] = useState(mainOption);

  const setRadio = (event) => {
    setOperation((operation = event.target.value));
  };

  //origin----------------------------------------------------------------------------------------------------
  let [origin, setOrigin] = useState(mainOrigin);

  const handleChangeOrigin = (e) => {
    setOrigin(e);
  };

  let handleSelectOrigin = (e) => {
    geocodeByAddress(e)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setOriginLatLon(latLng);
      })
      .catch((error) => {
        console.error("Error", error);
      });
    setOrigin(e.toString());
  };

  //destiny-----------------------------------------------------------------------------------------------------
  let [destiny, setDestiny] = useState(mainDestiny);

  const handleChangeDestiny = (e) => {
    setDestiny(e);
  };

  let handleSelectDestiny = (e) => {
    geocodeByAddress(e)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setDestinyLatLon(latLng);
      })
      .catch((error) => {
        console.error("Error", error);
      });
    setDestiny(e.toString());
  };

  //-----------------------------------------------------------------------------------------------------------------------
  let [validacionDestiny, setValidationDestiny] = useState(false);
  let [validacionOrigin, setValidationOrigin] = useState(false);
  let [countryOrigin, setCountryOrigin] = useState("");
  let [countryDestiny, setCountryDestiny] = useState("");

  //operation and detailed origin validation ----------------------------------------------------------------------------------------------

  useEffect(() => {
    if (origin !== "") {
      if (operation !== "") {
        setMessage("");
        let aux = [];
        aux = origin.split(", ");
        if (aux.length >= 3) {
          aux.forEach((element) => {
            if (COUNTRIES.has(element)) {
              setCountryOrigin(element);
            }
          });
          setMessage("");
        } else {
          setValidationOrigin(false);
          setMessage(MoreDetailsOrigin);
        }
      } else {
        setValidationOrigin(false);
        setMessage(Operation);
      }
    }
  }, [origin, operation]);

  let [validCountryOrigin, setValidCountryOrigin] = useState(null);

  useEffect(() => {
    if (countryOrigin !== "") {
      if (VALIDCOUNTRIES.has(countryOrigin)) {
        setMessage("");
        setValidCountryOrigin(true);
      } else {
        setCountryOrigin("");
        setValidCountryOrigin(false);
        setMessage(InvalidImportationOrigin);
      }
    }
  }, [countryOrigin]);

  //operation and detailed destination validation ------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (destiny !== "") {
      if (operation !== "") {
        setMessage("");
        let aux = [];
        aux = destiny.split(", ");
        if (aux.length >= 3) {
          aux.forEach((element) => {
            if (COUNTRIES.has(element)) {
              setCountryDestiny(element);
            }
          });
          setMessage("");
        } else {
          setValidationDestiny(false);
          setMessage(MoreDetailsDestiny);
        }
      } else {
        setValidationDestiny(false);
        setMessage(Operation);
      }
    }
  }, [destiny, operation]);

  let [validCountryDestiny, setValidCountryDestiny] = useState(null);

  useEffect(() => {
    if (countryDestiny !== "") {
      if (VALIDCOUNTRIES.has(countryDestiny)) {
        setMessage("");
        setValidCountryDestiny(true);
      } else {
        setCountryDestiny("");
        setValidCountryDestiny(false);
        setMessage(InvalidImportationDestiny);
      }
    }
  }, [countryDestiny]);

  //validation import, export ------------------------------------------------------------------------------------------

  useEffect(() => {
    if (
      operation !== "" &&
      (validCountryOrigin === false || validCountryDestiny === false)
    ) {
      setMessage(InvalidImportation);
    } else if (operation !== "" && validCountryOrigin && validCountryDestiny) {
      if (countryOrigin === countryDestiny) {
        setValidationOrigin(false);
        setValidationDestiny(false);
        setMessage(InvalidImportation);
      } else {
        setValidationOrigin(true);
        setValidationDestiny(true);
        setMessage();
      }
    }
  }, [
    operation,
    validCountryOrigin,
    validCountryDestiny,
    countryOrigin,
    countryDestiny,
  ]);

  //post servicio ingresarconsulta 1 y 2 ------------------------------------------------------------------------------------
  let [loading, setLoading] = useState(false);
  let [flagService1, setFlagService1] = useState(null);
  let [flagService2, setFlagService2] = useState(null);
  let [doorId, setDoorId] = useState(null);

  useEffect(() => {
    if (validacionOrigin && validacionDestiny) {
      setLoading(true);
      axios
        .post(apiEnterQuery2, {
          operacion: operation,
          pais_origen: countryOrigin,
          pais_destino: countryDestiny,
          puerta_origen: origin,
          puerta_destino: destiny,
          usuario: identify,
          api_key: key,
        })
        .then(function (responseApiId) {
          setFlagService2(true);
          setDoorId(responseApiId.data.FIPUERTAID);
          let aux = responseApiId.data.FIPUERTAID;
          axios
            .post(apiEnterQuery1, {
              destination_addresses: destiny,
              origin_addresses: origin,
              pais_destino: countryDestiny,
              pais_origen: countryOrigin,
              operacion: operation,
              usuario: identify,
              puertaId: aux,
              api_key: key,
            })
            .then(function (responseApiInsert) {
              if (responseApiInsert.status === OK_STATUS) {
                setLoading(false);
                setFlagService1(true);
              } else {
                setFlagService1(false);
              }
            })
            .catch(function (error) {
              setFlagService1(false);
            });
        })
        .catch(function (error) {
          setFlagService2(false);
        });
    }
  }, [
    validacionOrigin,
    validacionDestiny,
    apiEnterQuery1,
    apiEnterQuery2,
    origin,
    destiny,
    countryOrigin,
    countryDestiny,
    identify,
    key,
    operation,
  ]);

  let [visibleButtonContinue, setVisibleButtonContinue] = useState(false);

  useEffect(() => {
    if (
      (flagService1 !== null && flagService2 !== null) ||
      visibleButtonContinue
    ) {
      setLoading(false);
    }
  }, [flagService1, flagService2, visibleButtonContinue]);

  //mensaje falla servicio------------------------------------------------------------------------------------------
  useEffect(() => {
    if (flagService1 !== null && flagService2 !== null) {
      if (flagService1 === false || flagService2 === false) {
        setMessage(Response);
      }
    }
  }, [flagService1, flagService2]);

  //condition message error and button continue-------------------------------------------------------------------------------------------------------
  let [visibleErrorMessage, setVisibleErrorMessage] = useState(false);

  useEffect(() => {
    if (
      validacionOrigin === false ||
      validacionDestiny === false ||
      flagService1 === false ||
      flagService2 === false
    ) {
      setVisibleErrorMessage(true);
      setVisibleButtonContinue(false);
    }

    if (validacionOrigin && validacionDestiny && flagService1 && flagService2) {
      setVisibleButtonContinue(true);
      setVisibleErrorMessage(false);
    }
  }, [validacionOrigin, validacionDestiny, flagService1, flagService2]);

  let [validationMap, setValidationMap] = useState(false);

  useEffect(() => {
    if (
      originLatLon !== "" &&
      destinyLatLon !== "" &&
      origin !== "" &&
      destiny !== ""
    ) {
      setValidationMap(true);
    } else {
      setValidationMap(false);
    }
  }, [originLatLon, destinyLatLon, origin, destiny]);

  const sendResult = () => {
    onOption(operation);
    onOrigin(origin);
    onDestiny(destiny);
    onDoor(doorId);
  };

  //return ------------------------------------------------------------------------------------------------------------------------------------
  return (
    <>
      <Header
        black="Ingresar"
        blue="consulta"
        user={user}
        name={name}
      />
      <div className="container1">
        <br />
        <div className="form-options">
          <label id="text-date">Fecha de consulta:</label>
          <label>{formattedDate}</label>
          <hr id="hr-enter-query" />

          <div onChange={setRadio} id="div-marginleft">
            <label>Operación: </label>
            {mainOption === "importacion" ? (
              <input type="radio" value="importacion" name="radio" defaultChecked />
            ) : (
              <input type="radio" value="importacion" name="radio" />
            )}
            <label>Importación</label>

            {mainOption === "exportacion" ? (
              <input type="radio" value="exportacion" name="radio" defaultChecked />
            ) : (
              <input type="radio" value="exportacion" name="radio" />
            )}
            <label>Exportación</label>
          </div>

          <div className="left">
            <label>Ubicación origen</label>
            <PlacesAutocomplete
              value={origin}
              onChange={handleChangeOrigin}
              onSelect={handleSelectOrigin}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loadingOrigin,
              }) => (
                <div>
                  <input
                    id="input-autocomplete"
                    {...getInputProps({
                      placeholder: "CP Ciudad País",
                      className: "location-search-input",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loadingOrigin && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";

                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="rigth">
            <label>Ubicación destino</label>
            <br />
            <PlacesAutocomplete
              value={destiny}
              onChange={handleChangeDestiny}
              onSelect={handleSelectDestiny}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loadingDestiny,
              }) => (
                <div>
                  <input
                    id="input-autocomplete"
                    {...getInputProps({
                      placeholder: "CP Ciudad País",
                      className: "location-search-input",
                    })}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loadingDestiny && <div>Loading...</div>}
                    {suggestions.map((suggestion, index) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";

                      const style = suggestion.active
                        ? { backgroundColor: "#fafafa", cursor: "pointer" }
                        : { backgroundColor: "#ffffff", cursor: "pointer" };
                      return (
                        <div
                          key={index}
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
        </div>

        {validationMap ? (
          <div className="container-map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyALHbPavhgf4RlNrLnG3GTO_1lrw0fCQ4Y&libraries=places",
              }}
              defaultCenter={{
                lat: originLatLon.lat,
                lng: originLatLon.lng,
              }}
              defaultZoom={1}
              markers={[
                { lat: originLatLon.lat, lng: originLatLon.lng },
                { lat: destinyLatLon.lat, lng: destinyLatLon.lng },
              ]}
            >
              <AnyReactComponent
                lat={originLatLon.lat}
                lng={originLatLon.lng}
                text="A"
              />

              <AnyReactComponent
                lat={destinyLatLon.lat}
                lng={destinyLatLon.lng}
                text="B"
              />
            </GoogleMapReact>
          </div>
        ) : (
          ""
        )}

        {loading ? <Loader /> : ""}

        {visibleButtonContinue ? (
          <div id="btn-continue-cont">
            <button
              type="button"
              className="btn-continue"
              onClick={() => {
                sendResult();
                navigate("/menuOptions");
              }}
            >
              Continuar
            </button>
          </div>
        ) : (
          ""
        )}

        {visibleErrorMessage ? (
          <div id="message-error">{messageModal}</div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
