const Response = "Error de respuesta, intente con otro origen y/o destino";
const Operation = "Debe colocar una operación";
const InvalidImportation =
  "La ubicación origen/destino que ingresó no se encuentra";
const InvalidImportationOrigin =
  "La ubicación origen que ingresó no se encuentra";
const InvalidImportationDestiny =
  "La ubicación destino que ingresó no se encuentra";
const InvalidExport = "La ubicación origen/destino que ingresó no se encuentra";
const MoreDetailsOriginImport =
  "Debe detallar más la ubicación origen o importación inválida";
const MoreDetailsOriginExport =
  "Debe detallar más la ubicación origen o exportación inválida";
const MoreDetailsDestinyImport =
  "Debe detallar más la ubicación destino o importacion inválida";
const MoreDetailsDestinyExport =
  "Debe detallar más la ubicación destino o exportación inválida";
const MoreDetailsOrigin = "Debe detallar más la ubicación origen";
const MoreDetailsDestiny = "Debe detallar más la ubicación destino";
const COLORS = ["red", "yellow", "blue", "green", "purple"];

const COUNTRIES = new Set([
  "Afganistán",
  "Albania",
  "Argelia",
  "Samoa Americana",
  "Andorra",
  "Angola",
  "Anguila",
  "Antártida",
  "Antigua y Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Isla Ascensión",
  "Australia",
  "Austria",
  "Azerbaiyán",
  "Bahamas",
  "Baréin",
  "Bangladesh",
  "Barbados",
  "Bielorrusia",
  "Bélgica",
  "Belice",
  "Benín",
  "Bermudas",
  "Bután",
  "Bolivia",
  "Bosnia y Herzegovina",
  "Botsuana",
  "Isla Bouvet",
  "Brasil",
  "Territorio Británico del Océano Índico",
  "Islas Vírgenes Británicas",
  "Brunéi",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Camboya",
  "Camerún",
  "Canadá",
  "Islas Canarias",
  "Cabo Verde",
  "Caribe Neerlandés",
  "Islas Caimán",
  "República Centroafricana",
  "Ceuta y Melilla",
  "Chad",
  "Chile",
  "China",
  "Isla de Navidad",
  "Isla Clipperton",
  "Islas Cocos",
  "Colombia",
  "Comoras",
  "Congo",
  "República Democrática del Congo",
  "Islas Cook",
  "Costa Rica",
  "Croacia",
  "Cuba",
  "Curazao",
  "Chipre",
  "República Checa",
  "Costa de Marfil",
  "Dinamarca",
  "Diego García",
  "Yibuti",
  "Dominica",
  "República Dominicana",
  "Ecuador",
  "Egipto",
  "El Salvador",
  "Guinea Ecuatorial",
  "Eritrea",
  "Estonia",
  "Esuatini",
  "Etiopía",
  "Islas Malvinas",
  "Islas Feroe",
  "Fiyi",
  "Finlandia",
  "Francia",
  "Guayana Francesa",
  "Polinesia Francesa",
  "Territorios Australes Franceses",
  "Gabón",
  "Gambia",
  "Georgia",
  "Alemania",
  "Ghana",
  "Gibraltar",
  "Grecia",
  "Groenlandia",
  "Granada",
  "Guadalupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bisáu",
  "Guyana",
  "Haití",
  "Islas Heard y McDonald",
  "Honduras",
  "Hong Kong",
  "Hungría",
  "Islandia",
  "India",
  "Indonesia",
  "Irán",
  "Irak",
  "Irlanda",
  "Isla de Man,Israel",
  "Italia",
  "Jamaica",
  "Japón",
  "Jersey",
  "Jordania",
  "Kazajistán",
  "Kenia",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kirguistán",
  "Laos",
  "Letonia",
  "Líbano",
  "Lesoto",
  "Liberia",
  "Libia",
  "Liechtenstein",
  "Lituania",
  "Luxemburgo",
  "Macao",
  "Madagascar",
  "Malaui",
  "Malasia",
  "Maldivas",
  "Malí",
  "Malta",
  "Islas Marshall",
  "Martinica",
  "Mauritania",
  "Mauricio",
  "Mayotte",
  "México",
  "Micronesia",
  "Moldavia",
  "Mónaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Marruecos",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Países Bajos",
  "Nueva Caledonia",
  "Nueva Zelanda",
  "Nicaragua",
  "Níger",
  "Nigeria",
  "Niue",
  "Isla Norfolk",
  "Corea del Norte",
  "Macedonia del Norte",
  "Islas Marianas del Norte",
  "Noruega",
  "Omán",
  "Pakistán",
  "Palau",
  "Palestina",
  "Panamá",
  "Papúa",
  "Nueva Guinea",
  "Paraguay",
  "Perú",
  "Filipinas",
  "Islas Pitcairn",
  "Polonia",
  "Portugal",
  "Puerto Rico",
  "Catar",
  "Rumania",
  "Rusia,Ruanda",
  "Reunión",
  "Samoa",
  "San Marino",
  "Arabia Saudita",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leona",
  "Singapur",
  "Isla de San Martín",
  "Eslovaquia",
  "Eslovenia",
  "Islas Salomón",
  "Somalia",
  "Sudáfrica",
  "Islas Georgia del Sur y Sándwich del Sur",
  "Corea del Sur",
  "Sudán del Sur",
  "España",
  "Sri Lanka",
  "San Bartolomé",
  "Santa Elena",
  "San Cristóbal y Nieves",
  "Santa Lucía",
  "Isla de San Martín",
  "San Pedro y Miquelón",
  "San Vicente y las Granadinas",
  "Sudán",
  "Surinam",
  "Islas Svalbard y Jan Mayen",
  "Suecia",
  "Suiza",
  "Siria",
  "Santo Tomé y Príncipe",
  "Taiwán",
  "Tayikistán",
  "Tanzania",
  "Tailandia",
  "Timor Oriental",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad y Tobago",
  "Tristán de Acuña",
  "Túnez",
  "Turquía",
  "Turkmenistán",
  "Islas Turcas y Caicos",
  "Tuvalu",
  "Islas Ultramarinas Menores de Estados Unidos",
  "Islas Vírgenes de los EE.UU.",
  "Uganda",
  "Ucrania",
  "Emiratos Árabes Unidos",
  "Reino Unido",
  "Estados Unidos",
  "Uruguay",
  "Uzbekistán",
  "Vanuatu",
  "EE. UU.",
]);

const VALIDCOUNTRIES = new Set([
  "Alemania",
  "Angola",
  "Antigua y Barbuda",
  "Argentina",
  "Asia",
  "Australia",
  "Bahamas",
  "Bélgica",
  "Brasil",
  "Canadá",
  "Chile",
  "China",
  "Colombia",
  "Corea del Sur",
  "Costa Rica",
  "Cuba",
  "E.U.",
  "Ecuador",
  "El Salvador",
  "Emiratos árabes unidos",
  "España",
  "Francia",
  "Guatemala",
  "Honduras",
  "India",
  "Italia",
  "Jamaica",
  "Japón",
  "Malta",
  "Marruecos",
  "México",
  "Nicaragua",
  "Nueva Zelanda",
  "Países Bajos",
  "Panamá",
  "Perú",
  "Portugal",
  "Reino Unido",
  "República de China",
  "República Dominicana",
  "Senegal",
  "Sudáfrica",
  "Uruguay",
  "EE. UU.",
  "Estados Unidos",
]);

const AUXILIAR_ROUTE = {
  FCDESVTIEMPODESCARGA: null,
  FCDESVTIEMPOLLEGADA: null,
  FCDESVTIEMPOREV: null,
  FCDISTANCIAPUERTADESTINO: null,
  FCDISTANCIAPUERTOORIGEN: null,
  FCNAVIERA: null,
  FCPROBABILIDADSEMAFORO: null,
  FCPUERTADESTINO: null,
  FCPUERTAORIGEN: null,
  FCPUERTODESTINO: null,
  FCPUERTOORIGEN: null,
  FCRUTA: null,
  FCTIEMPO: null,
  FCTIEMPOCARGA: null,
  FCTIEMPODESCARGA: null,
  FCTIEMPONAVIERA: null,
  FCTIEMPOPAGO: null,
  FCTIEMPOPUERTADESTINO: null,
  FCTIEMPOPUERTOORIGEN: null,
  FCTIEMPORECMARITIMO: null,
  FCTIEMPOREVISION: null,
  FICONFIABILIDAD: null,
  FIOPCION: null,
  FIPROBABILIDADCARGA: null,
  FIPROBABILIDADDESCARGA: null,
  FIPROBABILIDADPAGO: null,
  FIUSUARIOID: null,
  CRITERIO: null,
};

export {
  Response,
  Operation,
  InvalidImportation,
  InvalidImportationOrigin,
  InvalidImportationDestiny,
  InvalidExport,
  MoreDetailsOriginImport,
  MoreDetailsOriginExport,
  MoreDetailsDestinyImport,
  MoreDetailsDestinyExport,
  MoreDetailsOrigin,
  MoreDetailsDestiny,
  COUNTRIES,
  VALIDCOUNTRIES,
  COLORS,
  AUXILIAR_ROUTE
};
