import React from "react";
import "../../assets/css/App.css";
import Logo from "../../assets/img/OSHIPP_LOGO-04.png";
import UserMenu from "./modals/UserMenu";
import { FaUserCircle } from "react-icons/fa";

export default function Header({ black, blue, user, name }) {
  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <header>
      <div className="column1">
        <img src={Logo} alt="" />
      </div>
      <div className="column2">
        <b>
          {black} <b className="modal-ro-blue">{blue}</b>
        </b>
      </div>
      <div className="column3">
        <FaUserCircle
          onClick={handleOpen}
          className="column3-btn "
        />
      </div>
      <UserMenu
        open={open}
        handleClose={handleClose}
        user={user}
        name={name}
      />
    </header>
  );
}
