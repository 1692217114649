import React, { useState, useEffect } from "react";
import "../assets/css/shippingLines.css";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { useNavigate } from "react-router-dom";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { StyledTableRow } from "./common/styles/StyledTableRow";
import { StyledTableCell } from "./common/styles/StyledTableCell";

import Loader from "./common/Loader";

import axios from "axios";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";
import { ERRORS, APIBADRESPONSE } from "./common/constants/ConstantsErrors";
import { EMPTY, ERRORSERVICE } from "./common/constants/ConstantsNumbers";

import Image1 from "../assets/img/naviera/2M_AllianceHamburgSudSafmarineSeaLand_TA3NEUATL3.jpg";
import Image2 from "../assets/img/naviera/CaribbeanFeederZimSeaboardPanCaribbeanMexicoService.jpg";
import Image3 from "../assets/img/naviera/CMA_CGM_AliancaANL_HamburgSudMaerskSeaLandBrazil Express.jpg";
import Image4 from "../assets/img/naviera/CMA_CGM_COSCO_HamSud_Hapag-Lloyd_Alianca_ANL_APL_PEX2 rtw eb.jpg";
import Image5 from "../assets/img/naviera/CMA_CGM_COSCO_HapagAliancaANL_APL_HamSud_MaerskOOCL_SealandPEX2_rtweb.jpg";
import Image6 from "../assets/img/naviera/CMA_CGM_IndigoService.jpg";
import Image7 from "../assets/img/naviera/CMA_CGM_MarfretMediterraneanCaribbeanServiceMEDCARIB.jpg";
import Image8 from "../assets/img/naviera/COSCO_CMA_CGM_ANL_APL_EvergreenLineWanHai_WSA3.jpg";
import Image9 from "../assets/img/naviera/Evergreen LineCOSCOYangMing APL_CMA_CGM_PIL_WanHaiWSA.jpg";
import Image10 from "../assets/img/naviera/HamburgSudMSC_AliancaMaerskLineHapagLloydSeaLandZim_UCLA.jpg";
import Image11 from "../assets/img/naviera/Hapag LloydHamburgSudAliancaZim_MedPacificServicesMPSMCPS.jpg";
import Image12 from "../assets/img/naviera/HapagLloydHamburgSudAliancaZimMedPacificServicesMPS_MCPS.jpg";
import Image13 from "../assets/img/naviera/HapagLloydONE_GulfExpressGCS.jpg";
import Image14 from "../assets/img/naviera/HapagLloydONE_HMM_MSC_AN1_AX1.jpg";
import Image15 from "../assets/img/naviera/HapagLloydONE_MSC_AliancaHamburgSudMaerskLineSeaLandZim_GS1_ANG.jpg";
import Image16 from "../assets/img/naviera/HapagLloydZimMGX.jpg";
import Image17 from "../assets/img/naviera/HoeghAutolinersEuropeCaribbeanUSEC.jpg";
import Image18 from "../assets/img/naviera/HoeghAutolinersEuropeCaribbeanUSEC_PCTC.jpg";
import Image19 from "../assets/img/naviera/HoeghAutolinersMiddleEastAfricaAmericasPCTC_one_way.jpg";
import Image20 from "../assets/img/naviera/HydeShippingSeaboardNorthAmericaCentralAmerica.jpg";
import Image21 from "../assets/img/naviera/K_LineSouthAmericaPCTC_service.jpg";
import Image22 from "../assets/img/naviera/K_LineTransatlanticLoopTAL_PCTC.jpg";
import Image23 from "../assets/img/naviera/LineaPeninsularHoustonProgresoService_mpc.jpg";
import Image24 from "../assets/img/naviera/LineaPeninsularPanamaCityProgresoServiceLoop_1_mpc.jpg";
import Image25 from "../assets/img/naviera/MaerskLine AliancaAPL_CMA_CGM_HamburgSudSeaLand_WCCA2.jpg";
import Image26 from "../assets/img/naviera/MaerskLineAliancaANL_APL_CMA_CGM_Hamburg SudSafmarineSeaLandWCCA.jpg";
import Image27 from "../assets/img/naviera/MaerskLineAliancaHamburgSud_AC2_AC3_Pendulum.jpg";
import Image28 from "../assets/img/naviera/MaerskLineSeaLand_ECUBEX.jpg";
import Image29 from "../assets/img/naviera/MelfiMarineMediterraneanCanadaService_MEDCAMEX.jpg";
import Image30 from "../assets/img/naviera/MSC_California Express.jpg";
import Image31 from "../assets/img/naviera/MSC_CaliforniaExpress.jpg";
import Image32 from "../assets/img/naviera/MSC_CanadaGulfBridge.jpg";
import Image33 from "../assets/img/naviera/MSC_ONE_HapagLloydHMM_AndesExpressAX2.jpg";
import Image34 from "../assets/img/naviera/NYK_RoRoSouthAmericaNorthAmerica_NS_PCTC.jpg";
import Image35 from "../assets/img/naviera/OCEAN_AllianceVictoryBridge_VICTORY.jpg";
import Image36 from "../assets/img/naviera/ONE_HapagLloydMAR_CCE.jpg";
import Image37 from "../assets/img/naviera/ONEHapag_Lloyd_HMM_MSC_AX3_AME1.jpg";
import Image38 from "../assets/img/naviera/SC_Line_MEXTRADE.jpg";
import Image39 from "../assets/img/naviera/Stinnes_MexicoService_mpc.jpg";
import Image40 from "../assets/img/naviera/The2M_AllianceHamburgSudAlianca_MEDGULF_TA6.jpg";
import Image41 from "../assets/img/naviera/TheAllianceTransatlanticLoop_4AL4.jpg";
import Image42 from "../assets/img/naviera/WalleniusWilhelmsenNorthAmericaEuropeSouthAmericaOneWayRoRoService.jpg";
import Image43 from "../assets/img/naviera/WalleniusWilhelmsenRTWRoRoService.jpg";
import Image44 from "../assets/img/naviera/WalleniusWilhelmsenSouthAmericaNorthAmericaServicePCTC_Loop_1.jpg";
import Image45 from "../assets/img/naviera/WanHaiPILEvergreenLineCOSCO_APL_CMA_CGM_YangMingASA.jpg";
import Image46 from "../assets/img/naviera/WorldDirectShippingCoatzacoalcosService.jpg";
import Image47 from "../assets/img/naviera/X-PressEvergreenLineCOSCO_OOCL_MCX_LAE.jpg";
import Image48 from "../assets/img/naviera/X-PressFeedersMexicoCentralAmericaX-Press_MCX.jpg";
import Image49 from "../assets/img/naviera/X_Press_COSCO_CMA_CGM_SeaboardMarineZIM_CentralAmericaCaribbeanServiceCCS.jpg";
import Image50 from "../assets/img/naviera/ZIM_MexicoTampaService_MTS.jpg";
import { styleErrorPage } from "./common/styles/Boxes";
import { Button, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FcInfo } from "react-icons/fc";
import { VALIDATION_SHIPPING } from "./common/constants/ConstantsMessages";

export default function ShippingLines({
  origin,
  destiny,
  countryO,
  countryD,
  onShipping,
  user,
  name,
  doorId,
  onError,
}) {
  const apiShippingLines = process.env.REACT_APP_API_SHIPPINGLINES;
  const key = process.env.REACT_APP_API_KEY;
  var historyObj = window.history;
  let [auxShipping, setShipping] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const verifyShippingLine = () => {
    if (!auxShipping) {
      handleOpen();
    } else {
      navigate("/routeItinerary");
    }
  };

  //footer
  let navigate = useNavigate();
  const siguiente = (
    <button
      type="button"
      className="right-footer"
      onClick={() => {
        navigate("/landRoutes");
      }}
    >
      Continuar
    </button>
  );
  const regresar = (
    <button
      type="button"
      className="left-footer"
      onClick={() => {
        navigate("/menuOptions");
      }}
    >
      Regresar
    </button>
  );

  const center = (
    <button className="btn_ir" onClick={verifyShippingLine}>
      <u>Itinierario de ruta</u>
    </button>
  );

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  //post axios service shipping lines ----------------------------------------------------------------------------------------
  let [information, setInformation] = useState([]);
  let [waitTime, setWaitTime] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setWaitTime(true);
    }, 20000);

    axios
      .post(apiShippingLines, {
        ubicacion_origen: origin,
        ubicacion_destino: destiny,
        pais_origen: countryO,
        pais_destino: countryD,
        puertaId: doorId,
        api_key: key,
      })
      .then(function (response) {
        if (
          response.data !== APIBADRESPONSE &&
          response.data.length !== EMPTY
        ) {
          setInformation(response.data);
        } else {
          onError(ERRORS.Service);
          navigate("/errorService");
        }
      })
      .catch(function (error) {
        if (error.response.status === ERRORSERVICE) {
          onError(ERRORS.Service);
        } else {
          onError(ERRORS.Generic);
        }
        navigate("/errorService");
      });
  }, [
    apiShippingLines,
    countryD,
    countryO,
    destiny,
    doorId,
    key,
    navigate,
    onError,
    origin,
  ]);

  useEffect(() => {
    if (waitTime && information.length <= EMPTY) {
      onError(ERRORS.TimeOut);
      navigate("/errorService");
    }
  }, [waitTime, information, navigate, onError]);

  let [urlImage, setURLimage] = useState("");

  const clickMe = (parameter) => (event) => {
    setURLimage(information[parameter].FCURL);
    setShipping(information[parameter].FCLINEANAVIERA);
    onShipping(information[parameter].FCLINEANAVIERA);
  };

  return (
    <>
      {information.length <= EMPTY ? (
        <Loader />
      ) : (
        <>
          <Header black="Líneas" blue="navieras" user={user} name={name} />
          <div className="container1">
            <div className="sl_title1">
              Ubicación orígen: {origin}
              <hr className="sl_hr" />
            </div>

            <div className="sl_title2">
              Ubicación destino: {destiny}
              <hr className="sl_hr" />
            </div>

            <div className="sl_table">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 140 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>LÍNEA NAVIERA</StyledTableCell>
                        <StyledTableCell className="column-width">
                          PUERTO DE SALIDA
                        </StyledTableCell>
                        <StyledTableCell className="column-width">
                          PUERTO DE ENTRADA
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {information.map((data, index) => (
                        <StyledTableRow
                          hover
                          style={{ width: "100%" }}
                          key={index}
                          onClick={clickMe(index)}
                        >
                          <StyledTableCell>
                            {data.FCLINEANAVIERA}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data.FCPUERTOORIGEN}
                          </StyledTableCell>
                          <StyledTableCell>
                            {data.FCPUERTODESTINO}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>

            <div className="sl_map">
              {urlImage !== "" ? renderSwitch(urlImage) : ""}
            </div>
          </div>
          <Footer sig={siguiente} reg={regresar} center={center} />

          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleErrorPage}>
              <div>
                <Typography id="modal-modal-title" variant="h6" component="h4">
                  <FcInfo size={40} />
                </Typography>

                <Typography id="modal-modal-description" sx={{ mt: 4, mb: 4 }}>
                  {VALIDATION_SHIPPING}
                </Typography>

                <Button onClick={handleClose} autoFocus>
                  OK
                </Button>
              </div>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

let renderSwitch = (param) => {
  switch (param) {
    case "../assets/img/naviera/2M_AllianceHamburgSudSafmarineSeaLand_TA3NEUATL3.jpg":
      return <img src={Image1} className="img-shipping" alt="" />;
    case "../assets/img/naviera/CaribbeanFeederZimSeaboardPanCaribbeanMexicoService.jpg":
      return <img src={Image2} className="img-shipping" alt="" />;
    case "../assets/img/naviera/CMA_CGM_AliancaANL_HamburgSudMaerskSeaLandBrazil Express.jpg":
      return <img src={Image3} className="img-shipping" alt="" />;
    case "../assets/img/naviera/CMA_CGM_COSCO_HamSud_Hapag-Lloyd_Alianca_ANL_APL_PEX2 rtw eb.jpg":
      return <img src={Image4} className="img-shipping" alt="" />;
    case "../assets/img/naviera/CMA_CGM_COSCO_HapagAliancaANL_APL_HamSud_MaerskOOCL_SealandPEX2_rtweb.jpg":
      return <img src={Image5} className="img-shipping" alt="" />;
    case "../assets/img/naviera/CMA_CGM_IndigoService.jpg":
      return <img src={Image6} className="img-shipping" alt="" />;
    case "../assets/img/naviera/CMA_CGM_MarfretMediterraneanCaribbeanServiceMEDCARIB.jpg":
      return <img src={Image7} className="img-shipping" alt="" />;
    case "../assets/img/naviera/COSCO_CMA_CGM_ANL_APL_EvergreenLineWanHai_WSA3.jpg":
      return <img src={Image8} className="img-shipping" alt="" />;
    case "../assets/img/naviera/Evergreen LineCOSCOYangMing APL_CMA_CGM_PIL_WanHaiWSA.jpg":
      return <img src={Image9} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HamburgSudMSC_AliancaMaerskLineHapagLloydSeaLandZim_UCLA.jpg":
      return <img src={Image10} className="img-shipping" alt="" />;
    case "../assets/img/naviera/Hapag LloydHamburgSudAliancaZim_MedPacificServicesMPSMCPS.jpg":
      return <img src={Image11} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HapagLloydHamburgSudAliancaZimMedPacificServicesMPS_MCPS.jpg":
      return <img src={Image12} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HapagLloydONE_GulfExpressGCS.jpg":
      return <img src={Image13} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HapagLloydONE_HMM_MSC_AN1_AX1.jpg":
      return <img src={Image14} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HapagLloydONE_MSC_AliancaHamburgSudMaerskLineSeaLandZim_GS1_ANG.jpg":
      return <img src={Image15} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HapagLloydZimMGX.jpg":
      return <img src={Image16} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HoeghAutolinersEuropeCaribbeanUSEC.jpg":
      return <img src={Image17} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HoeghAutolinersEuropeCaribbeanUSEC_PCTC.jpg":
      return <img src={Image18} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HoeghAutolinersMiddleEastAfricaAmericasPCTC_one_way.jpg":
      return <img src={Image19} className="img-shipping" alt="" />;
    case "../assets/img/naviera/HydeShippingSeaboardNorthAmericaCentralAmerica.jpg":
      return <img src={Image20} className="img-shipping" alt="" />;
    case "../assets/img/naviera/K_LineSouthAmericaPCTC_service.jpg":
      return <img src={Image21} className="img-shipping" alt="" />;
    case "../assets/img/naviera/K_LineTransatlanticLoopTAL_PCTC.jpg":
      return <img src={Image22} className="img-shipping" alt="" />;
    case "../assets/img/naviera/LineaPeninsularHoustonProgresoService_mpc.jpg":
      return <img src={Image23} className="img-shipping" alt="" />;
    case "../assets/img/naviera/LineaPeninsularPanamaCityProgresoServiceLoop_1_mpc.jpg":
      return <img src={Image24} className="img-shipping" alt="" />;
    case "../assets/img/naviera/MaerskLine AliancaAPL_CMA_CGM_HamburgSudSeaLand_WCCA2.jpg":
      return <img src={Image25} className="img-shipping" alt="" />;
    case "../assets/img/naviera/MaerskLineAliancaANL_APL_CMA_CGM_Hamburg SudSafmarineSeaLandWCCA.jpg":
      return <img src={Image26} className="img-shipping" alt="" />;
    case "../assets/img/naviera/MaerskLineAliancaHamburgSud_AC2_AC3_Pendulum.jpg":
      return <img src={Image27} className="img-shipping" alt="" />;
    case "../assets/img/naviera/MaerskLineSeaLand_ECUBEX.jpg":
      return <img src={Image28} className="img-shipping" alt="" />;
    case "../assets/img/naviera/MelfiMarineMediterraneanCanadaService_MEDCAMEX.jpg":
      return <img src={Image29} className="img-shipping" alt="" />;
    case "../assets/img/naviera/MSC_California Express.jpg":
      return <img src={Image30} className="img-shipping" alt="" />;
    case "../assets/img/naviera/MSC_CaliforniaExpress.jpg":
      return <img src={Image31} className="img-shipping" alt="" />;
    case "../assets/img/naviera/MSC_CanadaGulfBridge.jpg":
      return <img src={Image32} className="img-shipping" alt="" />;
    case "../assets/img/naviera/MSC_ONE_HapagLloydHMM_AndesExpressAX2.jpg":
      return <img src={Image33} className="img-shipping" alt="" />;
    case "../assets/img/naviera/NYK_RoRoSouthAmericaNorthAmerica_NS_PCTC.jpg":
      return <img src={Image34} className="img-shipping" alt="" />;
    case "../assets/img/naviera/OCEAN_AllianceVictoryBridge_VICTORY.jpg":
      return <img src={Image35} className="img-shipping" alt="" />;
    case "../assets/img/naviera/ONE_HapagLloydMAR_CCE.jpg":
      return <img src={Image36} className="img-shipping" alt="" />;
    case "../assets/img/naviera/ONEHapag_Lloyd_HMM_MSC_AX3_AME1.jpg":
      return <img src={Image37} className="img-shipping" alt="" />;
    case "../assets/img/naviera/SC_Line_MEXTRADE.jpg":
      return <img src={Image38} className="img-shipping" alt="" />;
    case "../assets/img/naviera/Stinnes_MexicoService_mpc.jpg":
      return <img src={Image39} className="img-shipping" alt="" />;
    case "../assets/img/naviera/The2M_AllianceHamburgSudAlianca_MEDGULF_TA6.jpg":
      return <img src={Image40} className="img-shipping" alt="" />;
    case "../assets/img/naviera/TheAllianceTransatlanticLoop_4AL4.jpg":
      return <img src={Image41} className="img-shipping" alt="" />;
    case "../assets/img/naviera/WalleniusWilhelmsenNorthAmericaEuropeSouthAmericaOneWayRoRoService.jpg":
      return <img src={Image42} className="img-shipping" alt="" />;
    case "../assets/img/naviera/WalleniusWilhelmsenRTWRoRoService.jpg":
      return <img src={Image43} className="img-shipping" alt="" />;
    case "../assets/img/naviera/WalleniusWilhelmsenSouthAmericaNorthAmericaServicePCTC_Loop_1.jpg":
      return <img src={Image44} className="img-shipping" alt="" />;
    case "../assets/img/naviera/WanHaiPILEvergreenLineCOSCO_APL_CMA_CGM_YangMingASA.jpg":
      return <img src={Image45} className="img-shipping" alt="" />;
    case "../assets/img/naviera/WorldDirectShippingCoatzacoalcosService.jpg":
      return <img src={Image46} className="img-shipping" alt="" />;
    case "../assets/img/naviera/X_Press_COSCO_CMA_CGM_SeaboardMarineZIM_CentralAmericaCaribbeanServiceCCS.jpg":
      return <img src={Image47} className="img-shipping" alt="" />;
    case "../assets/img/naviera/X-PressEvergreenLineCOSCO_OOCL_MCX_LAE.jpg":
      return <img src={Image48} className="img-shipping" alt="" />;
    case "../assets/img/naviera/X-PressFeedersMexicoCentralAmericaX-Press_MCX.jpg":
      return <img src={Image49} className="img-shipping" alt="" />;
    case "../assets/img/naviera/ZIM_MexicoTampaService_MTS.jpg":
      return <img src={Image50} className="img-shipping" alt="" />;
    default:
      return "No se encontro una ruta";
  }
};
