import React from "react";
import "../assets/css/login.css";
import { useNavigate } from "react-router-dom";
import "../assets/css/App.css";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Font from "../assets/img/login/FONDO.png";
import Logo from "../assets/img/OSHIPP_LOGO-04.png";
import RecoverPassword from "./common/modals/RecoverPassword";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  Email: Yup.string()
    .email("El usuario debe ser un email")
    .required("Usuario requerido"),
  Password: Yup.string()
    .min(2, "Muy corto")
    .max(14, "Muy largo")
    .required("Contraseña requerida"),
});

export default function Login({ onLogingUpdate }) {
  const apiLogin = process.env.REACT_APP_LOGIN;
  //modal
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let navigate = useNavigate();
  var historyObj = window.history;

  React.useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  return (
    <div className="row">
      <div className="col1">
        <div className="container-form">
          <img src={Logo} className="logo" alt="" />
          <h1 className="login-title">
            <b>Accede a tu cuenta de O'ship</b>
          </h1>
          <hr id="hr-login" />
          <Formik
            initialValues={{
              Email: "",
              Password: "",
            }}
            validationSchema={DisplayingErrorMessagesSchema}
            onSubmit={(values) => {
              fetch(apiLogin + "/api/login", {
                method: "POST",
                credentials: "include",
                headers: { 
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  Email: values.Email,
                  Password: values.Password,
                }),
              })
                .then((response) => {
                  if (response.status === 200) {
                    onLogingUpdate(true);
                    navigate("/menu");
                  } else {
                    onLogingUpdate(false);
                    navigate("/");
                  }
                })
                .catch((error) => {
                  navigate("/");
                });
            }}
          >
            {({ errors, touched }) => (
              <Form className="login-form">
                <label className="login-label" style={{ marginRight: "272px" }}>
                  Correo electrónico
                </label>
                <br />
                <Field className="login-input" name="Email" />
                {touched.Email && errors.Email && (
                  <div className="login-error">{errors.Email}</div>
                )}
                <br />
                <label className="login-label" style={{ marginRight: "320px" }}>
                  Contraseña
                </label>
                <br />
                <Field
                  className="login-input"
                  name="Password"
                  type="Password"
                />
                {touched.Password && errors.Password && (
                  <div className="login-error">{errors.Password}</div>
                )}
                <br />
                <p id="p-recover-password">
                  ¿Olvidaste tu contraseña?
                  <button
                    type="button"
                    className="login-recoverP"
                    onClick={handleOpen}
                  >
                    Recupérala aqui
                  </button>
                </p>
                <button className="login-btn" type="submit">
                  Ingresar
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div className="col2">
        <img src={Font} id="font-right" alt="" />
      </div>

      <RecoverPassword open={open} handleClose={handleClose} />
    </div>
  );
}
