import React from "react";
//mui modal
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { AiOutlineWarning } from "react-icons/ai";
import { styleErrorPage } from "./common/styles/Boxes";
import Button from "@mui/material/Button";

export default function ErrorPage(error) {
  let navigate = useNavigate();
  return (
    <Box sx={styleErrorPage}>
      <div>
        <Typography>
          <AiOutlineWarning size={40} style={{ color: "orange" }} />
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          Algo salió mal, intenta de nuevo
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          {error.error.message}
        </Typography>
        <Button
          onClick={() => {
            navigate(error.error.redirect);
          }}
        >
          Regresar
        </Button>
      </div>
    </Box>
  );
}
