import React, { useState, useEffect } from "react";
import "../../assets/css/responseOption.css";
import { FIRSTPOSITION } from "./constants/ConstantsNumbers";
import {
  PROBABILITY,
  TIMEOFARRIVAL,
  RELIABILITY,
} from "./constants/ConstantsValidation";

export default function ResponseOption({
  information = [],
  option = [],
  index,
}) {
  let [paragraphOne, setParagraphOne] = useState("");
  let [paragraphTwo, setParagraphTwo] = useState("");
  let [paragraphThree, setParagraphThree] = useState("");

  useEffect(() => {
    switch (option[FIRSTPOSITION]) {
      case PROBABILITY:
        setParagraphOne(
          `Probabilidad de revisión: ${information[index].FCPROBABILIDADSEMAFORO}%`
        );
        setParagraphTwo(
          `Tiempo de revisión: ${information[index].FCTIEMPOREVISION} hr con desviación +- ${information[index].FCDESVTIEMPOREV} min`
        );
        setParagraphThree(
          `Tiempo de renta de contenedor: ${information[index].FCTIEMPOPAGO} días`
        );
        break;
      case TIMEOFARRIVAL:
        setParagraphOne(
          `Tiempo de llegada: ${information[index].FCTIEMPO} días con desviación +- ${information[index].FCDESVTIEMPOLLEGADA} min`
        );
        setParagraphTwo(
          `Tiempo de renta de contenedor: ${information[index].FCTIEMPOPAGO} días`
        );
        setParagraphThree("");
        break;
      case RELIABILITY:
        setParagraphOne(
          `Confiabilidad de naviera: ${information[index].FICONFIABILIDAD}%`
        );
        setParagraphTwo(
          `Tiempo de renta de contenedor: ${information[index].FCTIEMPOPAGO} días`
        );
        setParagraphThree("");
        break;
      default:
        break;
    }
  }, [index, information, option]);

  return (
    <div className="response_option">
      <div>
        <b>{paragraphOne}</b>
      </div>
      <label>{paragraphTwo}</label>
      <br />
      <label>{paragraphThree}</label>
    </div>
  );
}
