import React, { useState, useEffect, useContext } from "react";
import "../assets/css/routeOptions.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { OptionContext } from "./context/MyRouteOptions";

import Loader from "./common/Loader";

import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import { TableRow, TableCell } from "@mui/material";

import axios from "axios";
import Header from "./common/Header";
import Carretera from "../assets/img/routeOption/carretera.png";
import Ola from "../assets/img/routeOption/olaGrande.png";
import Triangulo from "../assets/img/routeOption/triangulo sin fondo.png";
import { FaTrashAlt } from "react-icons/fa";
import { FcInfo } from "react-icons/fc";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styleErrorPage } from "./common/styles/Boxes";

import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";
import { ERRORS } from "./common/constants/ConstantsErrors";
import {
  EMPTY,
  ERRORSERVICE,
  FIRSTPOSITION,
  SECONDPOSITION,
  THIRDPOSITION,
} from "./common/constants/ConstantsNumbers";
import DetailInPort from "./common/modals/DetailInPort";
import ResponseOption from "./common/ResponseOption";
import {
  PROBABILITY,
  RELIABILITY,
  TIMEOFARRIVAL,
} from "./common/constants/ConstantsValidation";
import {
  filterArray,
  formattedDataGraph,
  formattedAddOption,
  orderWithOneOption,
  orderWithTwoOption,
  orderWithThreeOption,
} from "./common/functions/RouteOptionFunctions";
import { HtmlTooltip } from "./common/styles/HtmlTooltip";
import {
  CONST_CONFIABILIDAD,
  CONST_PROBABILIDADSEMAFORO,
  CONST_TIEMPO,
} from "./common/constants/MessagesRouteOption";

const options = [PROBABILITY, TIMEOFARRIVAL, RELIABILITY];

let unique = [];
let arrayRutes = [];

export default function RouteOption({ user, identify, name, doorId, onError }) {
  var { arrayRouteOptions } = useContext(OptionContext);
  var { setArrayRouteOptions } = useContext(OptionContext);
  var { setRouteOptions } = useContext(OptionContext);
  var { setGraphOptions } = useContext(OptionContext);
  const apiRouteOptions = process.env.REACT_APP_API_ROUTEOPTIONS;
  const key = process.env.REACT_APP_API_KEY;
  var historyObj = window.history;
  var navigate = useNavigate();

  //modal
  const [openDetail, setOpenDetail] = useState(false);
  const handleOpenDetail = () => setOpenDetail(true);
  const handleCloseDetail = () => setOpenDetail(false);

  const [messageAlert, setMessageAlert] = useState("");
  const [visibleBtnContinue, setVisibleBtnContinue] = useState(false);
  const [visibleBtnOk, setVisibleBtnOk] = useState(false);

  const [openOrderer, setOpenOrderer] = useState(false);
  const handleOpenOrderer = () => setOpenOrderer(true);
  const handleCloseOrderer = () => setOpenOrderer(false);

  //service prod -------------------------------------------------------------------------------------------
  let [information, setinformation] = useState([]);
  let [OrdererInformation, setOrdererInformation] = useState([]);
  let [loading, setLoading] = useState(null);
  let [waitTime, setWaitTime] = useState(false);

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  useEffect(() => {
    arrayRutes = arrayRouteOptions;
    unique = arrayRutes.slice();
    setLoading(true);

    setTimeout(function () {
      setWaitTime(true);
    }, 20000);

    axios
      .post(apiRouteOptions, {
        usuario: identify,
        puertaId: doorId,
        api_key: key,
      })
      .then(function (response) {
        if (response.data) {
          setinformation(response.data);
          setOrdererInformation(response.data);
          setLoading(false);
        } else {
          onError(ERRORS.Service);
          navigate("/errorService");
        }
      })
      .catch(function (error) {
        if (error.response.status === ERRORSERVICE) {
          onError(ERRORS.Service);
        } else {
          onError(ERRORS.Generic);
        }
        navigate("/errorService");
      });
  }, [
    apiRouteOptions,
    doorId,
    identify,
    key,
    navigate,
    onError,
    arrayRouteOptions,
  ]);

  useEffect(() => {
    if (waitTime && loading) {
      onError(ERRORS.TimeOut);
      navigate("/errorService");
    }
  }, [waitTime, loading, navigate, onError]);

  //multipleoption-------------------------------------------------------------------------------------------
  const [multipleOption, setMultipleOption] = useState([]);
  const [resOption, setResOption] = useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setMultipleOption(typeof value === "string" ? value.split(",") : value);
  };

  useEffect(() => {
    if (multipleOption.length !== 0) {
      let aux = "";
      for (let i = 0; i < multipleOption.length; i++) {
        if (i === multipleOption.length - 1) {
          aux += multipleOption[i] + ".";
        } else {
          aux += multipleOption[i] + ", ";
        }
      }
      setResOption(aux);
    } else {
      setResOption("");
    }
    return () => {};
  }, [multipleOption]);

  //---------------------------------------ordener array-------------------------------------------------------------
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (multipleOption.length <= EMPTY) {
      setVisible(false);
    }
  }, [multipleOption]);

  //function handleConsult ==> depending on any of the 3 options this function sorts the array
  //with the help of javascript's sort function
  const handleConsult = () => {
    setVisibleBtnContinue(false);
    setVisibleBtnOk(true);
    if (multipleOption.length !== EMPTY) {
      setMessageAlert("Se ha ordenando su información");
      handleOpenOrderer();
      switch (multipleOption.length) {
        case 1:
          if (multipleOption[FIRSTPOSITION] === PROBABILITY) {
            setinformation(
              orderWithOneOption(CONST_PROBABILIDADSEMAFORO, information)
            );
          } else if (multipleOption[FIRSTPOSITION] === TIMEOFARRIVAL) {
            setinformation(orderWithOneOption(CONST_TIEMPO, information));
          } else if (multipleOption[FIRSTPOSITION] === RELIABILITY) {
            setinformation(
              orderWithOneOption(CONST_CONFIABILIDAD, information)
            );
          } else {
            setOrdererInformation(information);
          }
          setOrdererInformation(information);
          break;

        case 2:
          if (
            multipleOption[FIRSTPOSITION] === PROBABILITY &&
            multipleOption[SECONDPOSITION] === TIMEOFARRIVAL
          ) {
            setinformation(
              orderWithTwoOption(
                CONST_PROBABILIDADSEMAFORO,
                CONST_TIEMPO,
                information
              )
            );
          } else if (
            multipleOption[FIRSTPOSITION] === PROBABILITY &&
            multipleOption[SECONDPOSITION] === RELIABILITY
          ) {
            setinformation(
              orderWithTwoOption(
                CONST_PROBABILIDADSEMAFORO,
                CONST_CONFIABILIDAD,
                information
              )
            );
          } else if (
            multipleOption[FIRSTPOSITION] === TIMEOFARRIVAL &&
            multipleOption[SECONDPOSITION] === PROBABILITY
          ) {
            setinformation(
              orderWithTwoOption(
                CONST_TIEMPO,
                CONST_PROBABILIDADSEMAFORO,
                information
              )
            );
          } else if (
            multipleOption[FIRSTPOSITION] === TIMEOFARRIVAL &&
            multipleOption[SECONDPOSITION] === RELIABILITY
          ) {
            setinformation(
              orderWithTwoOption(CONST_TIEMPO, CONST_CONFIABILIDAD, information)
            );
          } else if (
            multipleOption[FIRSTPOSITION] === RELIABILITY &&
            multipleOption[SECONDPOSITION] === PROBABILITY
          ) {
            setinformation(
              orderWithTwoOption(
                CONST_CONFIABILIDAD,
                CONST_PROBABILIDADSEMAFORO,
                information
              )
            );
          } else if (
            multipleOption[FIRSTPOSITION] === RELIABILITY &&
            multipleOption[SECONDPOSITION] === TIMEOFARRIVAL
          ) {
            setinformation(
              orderWithTwoOption(CONST_CONFIABILIDAD, CONST_TIEMPO, information)
            );
          } else {
            setOrdererInformation(information);
          }
          setOrdererInformation(information);
          break;

        case 3:
          if (
            multipleOption[FIRSTPOSITION] === PROBABILITY &&
            multipleOption[SECONDPOSITION] === TIMEOFARRIVAL &&
            multipleOption[THIRDPOSITION] === RELIABILITY
          ) {
            setinformation(
              orderWithThreeOption(
                CONST_PROBABILIDADSEMAFORO,
                CONST_TIEMPO,
                CONST_CONFIABILIDAD,
                information
              )
            );
          } else if (
            multipleOption[FIRSTPOSITION] === PROBABILITY &&
            multipleOption[SECONDPOSITION] === RELIABILITY &&
            multipleOption[THIRDPOSITION] === TIMEOFARRIVAL
          ) {
            setinformation(
              orderWithThreeOption(
                CONST_PROBABILIDADSEMAFORO,
                CONST_CONFIABILIDAD,
                CONST_TIEMPO,
                information
              )
            );
          } else if (
            multipleOption[FIRSTPOSITION] === TIMEOFARRIVAL &&
            multipleOption[SECONDPOSITION] === PROBABILITY &&
            multipleOption[THIRDPOSITION] === RELIABILITY
          ) {
            setinformation(
              orderWithThreeOption(
                CONST_TIEMPO,
                CONST_PROBABILIDADSEMAFORO,
                CONST_CONFIABILIDAD,
                information
              )
            );
          } else if (
            multipleOption[FIRSTPOSITION] === TIMEOFARRIVAL &&
            multipleOption[SECONDPOSITION] === RELIABILITY &&
            multipleOption[THIRDPOSITION] === PROBABILITY
          ) {
            setinformation(
              orderWithThreeOption(
                CONST_TIEMPO,
                CONST_CONFIABILIDAD,
                CONST_PROBABILIDADSEMAFORO,
                information
              )
            );
          } else if (
            multipleOption[FIRSTPOSITION] === RELIABILITY &&
            multipleOption[SECONDPOSITION] === PROBABILITY &&
            multipleOption[THIRDPOSITION] === TIMEOFARRIVAL
          ) {
            setinformation(
              orderWithThreeOption(
                CONST_CONFIABILIDAD,
                CONST_PROBABILIDADSEMAFORO,
                CONST_TIEMPO,
                information
              )
            );
          } else if (
            multipleOption[FIRSTPOSITION] === RELIABILITY &&
            multipleOption[SECONDPOSITION] === TIMEOFARRIVAL &&
            multipleOption[THIRDPOSITION] === PROBABILITY
          ) {
            setinformation(
              orderWithThreeOption(
                CONST_CONFIABILIDAD,
                CONST_TIEMPO,
                CONST_PROBABILIDADSEMAFORO,
                information
              )
            );
          } else {
            setOrdererInformation(information);
          }
          setOrdererInformation(information);
          break;

        default:
          break;
      }
      setVisible(false);
      setTimeout(function () {
        setVisible(true);
      }, 1);
    } else {
      setMessageAlert("Se mostrarán tus opciones sin ordenar por un criterio");
      handleOpenOrderer();
      setOrdererInformation(information);
      setVisible(false);
    }
  };

  //pagination---------------------------------------------------------------------------------------------------
  const [page, setPage] = useState(1);

  const handlePagination = (event, value) => {
    setPage(value);
  };

  //array my options---------------------------------------------------------------------------------------------
  let [index, setIndex] = useState(1);
  let [refreshOptions, setRefresh] = useState(true);

  useEffect(() => {
    setIndex(page - 1);
    setVisible(false);
    setTimeout(function () {
      setVisible(true);
    }, 1);
  }, [page]);

  const addOption = () => {
    let auxRute = formattedAddOption(multipleOption, OrdererInformation, index);

    arrayRutes.push(auxRute);
    unique = filterArray(arrayRutes);

    setTimeout(function () {
      setRefresh(false);
    }, 1000);

    setRefresh(true);
  };

  const checkIfContinue = () => {
    var auxUnique = JSON.parse(JSON.stringify(unique));

    if (unique.length >= 3 && unique.length <= 5) {
      setVisibleBtnOk(false);
      setVisibleBtnContinue(true);
      setRouteOptions(unique);
      setGraphOptions(formattedDataGraph(auxUnique));
      setArrayRouteOptions(arrayRutes);
      navigate("/routeOptionsGraph");
    } else if (unique.length > 5) {
      let arrayAuxiliar = [];
      for (let i = 0; i < 5; i++) {
        arrayAuxiliar.push(unique[i]);
      }
      var arrayAuxiliarGraph = JSON.parse(JSON.stringify(arrayAuxiliar));
      setRouteOptions(arrayAuxiliar);
      setGraphOptions(formattedDataGraph(arrayAuxiliarGraph));
      setVisibleBtnOk(false);
      setVisibleBtnContinue(true);
      setMessageAlert("Solo se mostrarán las primeras 5 opciones");
      setArrayRouteOptions(arrayRutes);
      handleOpenOrderer();
    } else {
      setVisibleBtnContinue(false);
      setVisibleBtnOk(true);
      setMessageAlert("Debe agregar más de 2 opciones");
      handleOpenOrderer();
    }
  };
  //return---------------------------------------------------------------------------------------------------------
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Header black="Opciones" blue="de rutas" user={user} name={name} />
          <div className="container1">
            <div className="container-or">
              <div className="left-or">
                <div className="left-title-or">
                  <label id="text-mostrar">Mostrar por</label>
                  <FormControl
                    sx={{ width: 300, marginTop: "8px", height: "45px" }}
                  >
                    <Select
                      multiple
                      value={multipleOption}
                      onChange={handleChange}
                      renderValue={(selected) => selected.join(", ")}
                      style={{
                        height: "40px",
                        textAlign: "left",
                        backgroundColor: "white",
                      }}
                    >
                      {options.map((item) => (
                        <MenuItem key={item} value={item}>
                          <Checkbox
                            checked={multipleOption.indexOf(item) > -1}
                          />
                          <ListItemText primary={item} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <p>Se ordenará por:</p>
                        {resOption}
                      </React.Fragment>
                    }
                  >
                    <Button id="btn-consult" onClick={handleConsult}>
                      Consultar
                    </Button>
                  </HtmlTooltip>
                </div>

                {OrdererInformation.length <= EMPTY ? (
                  ""
                ) : (
                  <>
                    <Stack>
                      {visible ? (
                        <ResponseOption
                          information={OrdererInformation}
                          option={multipleOption}
                          index={index}
                        />
                      ) : (
                        ""
                      )}
                      <label id="label-page">{page}</label>
                      <div>
                        <div className="left-or-2">
                          <table className="table-or">
                            <thead>
                              <tr>
                                <td>
                                  <img
                                    src={Carretera}
                                    className="img-car"
                                    alt=""
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img src={Ola} id="img-ola" alt="" />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img
                                    src={Carretera}
                                    className="img-car"
                                    alt=""
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <HtmlTooltip
                                    title={
                                      <React.Fragment>
                                        <p>Detalle en puerto</p>
                                      </React.Fragment>
                                    }
                                  >
                                    <img
                                      src={Triangulo}
                                      id="img-flecha"
                                      alt=""
                                      onClick={handleOpenDetail}
                                    />
                                  </HtmlTooltip>
                                </td>
                              </tr>
                            </thead>
                            <tbody></tbody>
                          </table>
                        </div>

                        <div className="right-or-2">
                          <div style={{ height: "80px" }}>
                            {information[page - 1].FCPUERTAORIGEN}
                          </div>

                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <p>
                                  Tiempo de recorrido:{" "}
                                  {information[page - 1].FCTIEMPOPUERTOORIGEN}
                                </p>
                              </React.Fragment>
                            }
                          >
                            <div style={{ height: "58px" }}>
                              {information[page - 1].FCPUERTOORIGEN}
                            </div>
                          </HtmlTooltip>

                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <p>
                                  Tiempo de recorrido:{" "}
                                  {information[page - 1].FCTIEMPONAVIERA} días
                                </p>
                              </React.Fragment>
                            }
                          >
                            <div style={{ height: "68px" }}>
                              {information[page - 1].FCNAVIERA}
                            </div>
                          </HtmlTooltip>

                          <div style={{ height: "90px" }}>
                            {information[page - 1].FCPUERTODESTINO}
                          </div>

                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <p>
                                  Tiempo de recorrido:{" "}
                                  {information[page - 1].FCTIEMPOPUERTADESTINO}
                                </p>
                              </React.Fragment>
                            }
                          >
                            <div style={{ height: "35px" }}>
                              {information[page - 1].FCPUERTADESTINO}
                            </div>
                          </HtmlTooltip>
                        </div>
                      </div>
                      <div style={{ marginLeft: "300px" }}>
                        <Pagination
                          count={information.length}
                          defaultPage={1}
                          siblingCount={0}
                          page={page}
                          onChange={handlePagination}
                        />
                      </div>
                      <DetailInPort
                        openDetail={openDetail}
                        handleCloseDetail={handleCloseDetail}
                        item={information[page - 1]}
                        index={page}
                      />
                      <Modal
                        open={openOrderer}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                      >
                        <Box sx={styleErrorPage}>
                          <div>
                            <Typography
                              id="modal-modal-title"
                              variant="h6"
                              component="h4"
                            >
                              <FcInfo size={40} />
                            </Typography>

                            <Typography
                              id="modal-modal-description"
                              sx={{ mt: 4, mb: 4 }}
                            >
                              {messageAlert}
                            </Typography>
                            {visibleBtnContinue ? (
                              <Button
                                onClick={() => {
                                  navigate("/routeOptionsGraph");
                                }}
                                autoFocus
                              >
                                Acepto
                              </Button>
                            ) : (
                              ""
                            )}
                            {visibleBtnOk ? (
                              <Button onClick={handleCloseOrderer} autoFocus>
                                OK
                              </Button>
                            ) : (
                              ""
                            )}
                          </div>
                        </Box>
                      </Modal>
                    </Stack>
                  </>
                )}
              </div>

              <div className="right-or">
                <RouteOptionAccordion
                  addOption={addOption}
                  setRefresh={setRefresh}
                  refreshOptions={refreshOptions}
                  checkIfContinue={checkIfContinue}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </React.Fragment>
  );
}

const RouteOptionAccordion = ({
  addOption,
  setRefresh,
  refreshOptions,
  checkIfContinue,
}) => {
  let navigate = useNavigate();
  const [indexDelete, setIndex] = useState(null);

  const [openDelete, setOpenDelete] = useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  const deleteOption = () => {
    if (indexDelete !== null) {
      setTimeout(function () {
        arrayRutes.splice(indexDelete, 1);
        unique = arrayRutes;
        setRefresh(false);
      }, 100);
      setRefresh(true);
    }
    handleCloseDelete();
  };

  return (
    <div className="container-or">
      <Modal
        open={openDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleErrorPage}>
          <div>
            <Typography id="modal-modal-title" variant="h6" component="h4">
              <FcInfo size={40} />
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 4, mb: 4 }}>
              La opción se quitará de la lista
            </Typography>
            <Button onClick={handleCloseDelete}>Cancelar</Button>
            <Button onClick={deleteOption}>Acepto</Button>
          </div>
        </Box>
      </Modal>

      <div className="center-or">
        <HtmlTooltip
          title={
            <React.Fragment>
              <p>Agregar a mis opciones</p>
            </React.Fragment>
          }
        >
          <button className="button_central" onClick={addOption}>
            {">"}
          </button>
        </HtmlTooltip>
      </div>

      <div className="acordion">
        <div className="title_my_options">
          <p>Mis opciones</p>
        </div>

        <TableContainer
          sx={{ maxHeight: 400, width: "90%" }}
          className="table-margin-left"
        >
          <Table>
            <TableHead>
              <TableRow id="table-align-left">
                <TableCell>
                  <lable className="row-rute font-bold">Ruta</lable>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {unique.length === 0
                ? ""
                : unique.map((item, i) => (
                    <TableRow
                      key={i}
                      style={{ textAlign: "left" }}
                      className="border-body"
                    >
                      <TableCell>
                        <div className="accordion_summary">
                          <div className="item row-rute">
                            Ruta {item.FIOPCION}
                          </div>
                          <div className="item" style={{ textAlign: "center" }}>
                            <button
                              className="botton_accordion"
                              onClick={() => {
                                setIndex(i);
                                handleOpenDelete();
                              }}
                            >
                              <FaTrashAlt />
                            </button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="bottons_final">
        <button
          className="btn_accordeon"
          onClick={() => {
            navigate("/menuOptions");
          }}
        >
          Regresar
        </button>

        <button className="btn_accordeon" onClick={checkIfContinue}>
          Comparar rutas
        </button>
      </div>
    </div>
  );
};
