import React from "react";
import "../assets/css/menuOptions.css";
import Header from "./common/Header";
import { useNavigate } from "react-router-dom";
import RutaIcon from "../assets/img/menus/Icono ruta.png";
import TruckIcon from "../assets/img/menus/Icono camión.png";
import BoatIcon from "../assets/img/menus/Icono barco.png";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";

export default function MenuOptions({ user, name }) {
  let navigate = useNavigate();
  var historyObj = window.history;

  React.useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });
  
  return (
    <>
      <Header
        black="Opciones"
        blue="para consultar"
        user={user}
        name={name}
      />

      <div className="container2">
        <div className="margin-top-30">
          <label className="menu2-title">
            Selecciona lo que necesitas saber
          </label>
        </div>

        <hr id="hr-menu-options" />

        <div className="menu2-buttons">
          <div className="menu2-col">
            <button
              className="menu2-btn1"
              onClick={() => {
                navigate("/shippingLines");
              }}
            >
              <p className="margin-top-20">
                Líneas
                <br />
                navieras{" "}
              </p>
              <img src={BoatIcon} className="img-menu-options" alt="" />
            </button>
          </div>

          <div className="menu2-col2">
            <button
              className="menu2-btn2"
              onClick={() => {
                navigate("/landroutes");
              }}
            >
              <p className="margin-top-20">
                Rutas
                <br /> terrestres
              </p>
              <img src={TruckIcon} className="img-menu-options" alt="" />
            </button>
          </div>

          <div className="menu2-col3">
            <button
              className="menu2-btn3"
              onClick={() => {
                navigate("/routeOption");
              }}
            >
              <p className="margin-top-20">
                Opciones
                <br /> de rutas
              </p>
              <img src={RutaIcon} className="img-menu-options" alt="" />
            </button>
            <button
              className="menu2-btn-back"
              onClick={() => {
                navigate("/menu");
              }}
            >
              Regresar
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
