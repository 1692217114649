import React from "react";
//mui modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "25%",
  left: "87%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
};

export default function UserMenu({ open, handleClose, user, name }) {
  const apiLogin = process.env.REACT_APP_LOGIN;
  let navigate = useNavigate();

  const logout = () => {
    fetch(apiLogin + "/api/logout", {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="user-menu">
            <p className="user-menu-title">{name}<br/>{user}</p>
            <hr />
            <button
              className="user-menu-btn1"
              onClick={() => {
                navigate("/serviceRelationship");
              }}
            >
              Relación de consultas
            </button>
            <br />
            <br />
            <button
              className="user-menu-btn1"
              onClick={() => {
                navigate("/failedQueries");
              }}
            >
              Consultas fallidas
            </button>
            <br />
            <div id="container-close-session">
              <button className="user-name-btn2" onClick={logout}>
                Cerrar sesión
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
