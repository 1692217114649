import React, { useState, useEffect } from "react";
import "../assets/css/landroutes.css";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Slider from "@mui/material/Slider";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

import Loader from "./common/Loader";

import { StyledTableRow } from "./common/styles/StyledTableRow";
import { StyledTableCellLandRoutes } from "./common/styles/StyledTableCell";
import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";

import { ERRORS } from "./common/constants/ConstantsErrors";
import { EMPTY, ERRORSERVICE } from "./common/constants/ConstantsNumbers";

export default function LandRoutes({
  origin,
  destiny,
  user,
  identify,
  name,
  doorId,
  onError,
}) {
  const apiLandRoutes = process.env.REACT_APP_API_LANDROUTES;
  const key = process.env.REACT_APP_API_KEY;
  var historyObj = window.history;
  let [newOrigin, setNewOrigin] = useState(origin);
  let [newDestiny, setNewDestiny] = useState(destiny);
  //para footer
  let navigate = useNavigate();
  const siguiente = (
    <button
      className="right-footer"
      onClick={() => {
        navigate("/routeOption");
      }}
    >
      Continuar
    </button>
  );
  const regresar = (
    <button
      className="left-footer"
      onClick={() => {
        navigate("/menuOptions");
      }}
    >
      Regresar
    </button>
  );

  //post axios service landroutes ---------------------------------------------------------------------
  let [info, setInfo] = useState([]);
  let [flagTimeOut, setFlagTimeOut] = useState(false);

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  useEffect(() => {
    var regex = /[\u4e00-\u9fa5]/g;
    let auxOrigin = origin.replace(regex, "?");
    setNewOrigin(auxOrigin);
    let auxDestiny = destiny.replace(regex, "?");
    setNewDestiny(auxDestiny);
  }, [origin, destiny]);

  useEffect(() => {
    //with this timeout we give 15 seconds of time to the response of the service, 
    //if it exceeds this time the error flag is changed to true
    setTimeout(function () {
      setFlagTimeOut(true);
    }, 15000);

    axios
      .post(apiLandRoutes, {
        usuario: identify,
        puertaId: doorId,
        api_key: key,
      })
      .then(function (response) {
        if (!response.data) {
          onError(ERRORS.Service);
          navigate("/errorService");
        }else{
          setInfo(response.data);
        }
      })
      .catch(function (error) {
        if (error.response.status === ERRORSERVICE) {
          onError(ERRORS.Service);
        } else {
          onError(ERRORS.Generic);
        }
        navigate("/errorService");
      });
  }, [apiLandRoutes, doorId, identify, key, navigate, onError]);

  useEffect(() => {
    if (flagTimeOut && info.length <= EMPTY) {
      onError(ERRORS.TimeOut);
      navigate("/errorService");
    }
  }, [flagTimeOut, info, navigate, onError]);

  //slider ---------------------------------------------------------------------------------------------
  const [sliderValue, setSliderValue] = useState(40);
  const updateRange = (e, data) => {
    setSliderValue(data);
  };

  let [arrayTable, setArray] = useState([]);
  useEffect(() => {
    if (sliderValue !== null && info.length > 1) {
      let auxArray = [];
      switch (sliderValue) {
        case 40:
          info.forEach((item) => {
            if (item.FIVELOCIDAD === 40) {
              auxArray.push(item);
            }
          });
          setArray(auxArray);
          break;
        case 50:
          info.forEach((item) => {
            if (item.FIVELOCIDAD === 50) {
              auxArray.push(item);
            }
          });
          setArray(auxArray);
          break;
        case 60:
          info.forEach((item) => {
            if (item.FIVELOCIDAD === 60) {
              auxArray.push(item);
            }
          });
          setArray(auxArray);
          break;
        case 70:
          info.forEach((item) => {
            if (item.FIVELOCIDAD === 70) {
              auxArray.push(item);
            }
          });
          setArray(auxArray);
          break;
        case 80:
          info.forEach((item) => {
            if (item.FIVELOCIDAD === 80) {
              auxArray.push(item);
            }
          });
          setArray(auxArray);
          break;
        case 90:
          info.forEach((item) => {
            if (item.FIVELOCIDAD === 90) {
              auxArray.push(item);
            }
          });
          setArray(auxArray);
          break;
        case 100:
          info.forEach((item) => {
            if (item.FIVELOCIDAD === 100) {
              auxArray.push(item);
            }
          });
          setArray(auxArray);
          break;
        default:
          break;
      }
    }
  }, [sliderValue, info]);

  //return --------------------------------------------------------------------------------------------
  return (
    <>
      {info.length <= EMPTY ? (
        <Loader />
      ) : (
        <>
          <Header black="Rutas" blue="terrestres" user={user} name={name} />
          <div className="container1">
            <div>
              <div className="left-landroutes">
                <div>
                  <label className="left-landroutes-blue">
                    Puerta orígen: {origin}
                  </label>
                  <hr className="left-landroutes-blue-hr" />
                </div>
                <div>
                  <label className="left-landroutes-blue">
                    Puerta destino: {destiny}
                  </label>
                  <hr className="left-landroutes-blue-hr" />
                </div>
              </div>
              <div className="right-landroutes">
                <label className="title-slider">Velocidad: </label>
                <Slider
                  id="slider"
                  onChange={updateRange}
                  min={40}
                  max={100}
                  defaultValue={40}
                  aria-label="Default"
                  className="slider-landroutes"
                  step={10}
                />
              </div>
              <div>
                <label id="slider-text-1">40km/hr</label>
                <label id="slider-text-2">70km/hr</label>
                <label id="slider-text-3">100km/hr</label>
                <p className="size-10">
                  Ajuste la velocidad de acuerdo al tipo de transporte para una
                  estimación más precisa
                </p>
              </div>
            </div>
            {arrayTable.length < 1 ? (
              ""
            ) : (
              <Tables
                information={arrayTable}
                origin={newOrigin}
                destiny={newDestiny}
              />
            )}
          </div>
          <Footer sig={siguiente} reg={regresar} />
        </>
      )}
    </>
  );
}

const Tables = ({ information = [], origin, destiny }) => {
  let [vo, setVo] = useState(false);
  let [vd, setVd] = useState(false);

  useEffect(() => {
    let contO = 0;
    let contD = 0;
    for (let i = 0; i < information.length; i++) {
      if (information[i].FCORIGEN === origin) {
        contO += 1;
      } else if (information[i].FCORIGEN === destiny) {
        contD += 1;
      }
    }
    if (contO > 0) {
      setVo(true);
    } else {
      setVo(false);
    }
    if (contD > 0) {
      setVd(true);
    } else {
      setVd(false);
    }
  }, [destiny, information, origin]);

  return (
    <>
      {vo ? (
        <div className="tables-landroutes">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 110 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCellLandRoutes>
                      PUERTO SALIDA
                    </StyledTableCellLandRoutes>
                    <StyledTableCellLandRoutes className="width-370">
                      DISTANCIA DEL PUNTO ORÍGEN AL PUERTO DE SALIDA (KM)
                    </StyledTableCellLandRoutes>
                    <StyledTableCellLandRoutes className="width-200">
                      TIEMPO DE TRAYECTO (Hrs)
                    </StyledTableCellLandRoutes>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {information.map((item, index) => {
                    if (item.FCORIGEN === origin) {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCellLandRoutes>
                            {item.FCPUERTO}
                          </StyledTableCellLandRoutes>
                          <StyledTableCellLandRoutes>
                            {item.FIDISTANCIA}
                          </StyledTableCellLandRoutes>
                          <StyledTableCellLandRoutes>
                            {item.FCTIEMPO}
                          </StyledTableCellLandRoutes>
                        </StyledTableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      ) : (
        ""
      )}

      {vd ? (
        <div className="tables-landroutes">
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 155 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <StyledTableCellLandRoutes>
                      PUERTO ENTRADA
                    </StyledTableCellLandRoutes>
                    <StyledTableCellLandRoutes className="width-370">
                      DISTANCIA DEL PUERTO DE ENTRADA AL PUNTO DESTINO (KM)
                    </StyledTableCellLandRoutes>
                    <StyledTableCellLandRoutes className="width-200">
                      TIEMPO DE TRAYECTO (Hrs)
                    </StyledTableCellLandRoutes>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {information.map((item, index) => {
                    if (item.FCORIGEN === destiny) {
                      return (
                        <StyledTableRow key={index}>
                          <StyledTableCellLandRoutes>
                            {item.FCPUERTO}
                          </StyledTableCellLandRoutes>
                          <StyledTableCellLandRoutes>
                            {item.FIDISTANCIA}
                          </StyledTableCellLandRoutes>
                          <StyledTableCellLandRoutes>
                            {item.FCTIEMPO}
                          </StyledTableCellLandRoutes>
                        </StyledTableRow>
                      );
                    }
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
