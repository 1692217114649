const EMPTY = 0;
const FIRSTPOSITION = 0;
const SECONDPOSITION = 1;
const THIRDPOSITION = 2;
const FOURTHPOSITION = 3;
const FIFTHPOSITION = 4;
const ERRORSERVICE = 502;
const OK_STATUS = 200;
const DELETE_ONE = 1;

export {
  EMPTY,
  FIRSTPOSITION,
  SECONDPOSITION,
  THIRDPOSITION,
  FOURTHPOSITION,
  FIFTHPOSITION,
  ERRORSERVICE,
  OK_STATUS,
  DELETE_ONE,
};
