const styleDetailInPort = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const styleErrorPage = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    height: 180,
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    backgroundColor: "white",
    color: "black",
    textAlign: "center",
  };

export { styleDetailInPort, styleErrorPage };
