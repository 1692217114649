import React from "react";
import "../../assets/css/App.css";

export default function Footer(props) {
  let { sig, reg, center } = props;
  return (
    <footer>
      <>{sig}</>
      <>{center}</>
      <>{reg}</>
    </footer>
  );
}
