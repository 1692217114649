import "./assets/css/App.css";
import React from "react";
import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import Login from "./components/Login";
import Menu from "./components/Menu";
import EnterQuery from "./components/EnterQuery";
import MenuOptions from "./components/MenuOptions";
import RouteOption from "./components/RouteOption";
import ShippingLines from "./components/ShippingLines";
import LandRoutes from "./components/LandRoutes";
import ServiceRelationship from "./components/ServiceRelationship";
import RouteItinerary from "./components/RouteItinerary";
import LatestQueries from "./components/LatestQueries";
import FailedQueries from "./components/FailedQueries";
import { COUNTRIES } from "./components/common/constants/Constans";
import RouteOptionsGraph from "./components/RouteOptionsGraph";
import { desactivateBrowserButtons } from "./components/common/functions/noBrowserButtons";
import ErrorPage from "./components/ErrorPage";
import OptionContext from "./components/context/MyRouteOptions";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [option, setOption] = useState("");
  const [origin, setOrigin] = useState("");
  const [destiny, setDestiny] = useState("");
  const [date, setDate] = useState("NA");
  const [countryO, setCO] = useState("");
  const [countryD, setCD] = useState("");
  const [shipping, setShipping] = useState("");
  const [doorId, setDoorId] = useState(null);
  const [error, setError] = useState(null);

  const [name, setName] = useState("");
  const [user, setUser] = useState("");
  const [identify, setIdentify] = useState(null);

  var historyObj = window.history;

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  useEffect(() => {
    let aux = [];
    if (origin !== "") {
      aux = origin.split(", ");
      aux.forEach((element) => {
        if (COUNTRIES.has(element)) {
          setCO(element);
        }
      });
    }
  }, [origin]);

  useEffect(() => {
    let aux = [];
    if (destiny !== "") {
      aux = destiny.split(", ");
      aux.forEach((element) => {
        if (COUNTRIES.has(element)) {
          setCD(element);
        }
      });
    }
  }, [destiny]);

  return (
    <div>
      <OptionContext>
        <BrowserRouter>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Login onLogingUpdate={setIsLoggedIn} onError={setError} />
              }
            />
            {isLoggedIn && (
              <>
                <Route
                  exact
                  path="/menu"
                  element={
                    <Menu
                      user={user}
                      name={name}
                      onOption={setOption}
                      onOrigin={setOrigin}
                      onDestiny={setDestiny}
                      onDate={setDate}
                      onShipping={setShipping}
                      onDoor={setDoorId}
                      setUser={setUser}
                      setIdentify={setIdentify}
                      setIsLoggedIn={setIsLoggedIn}
                      setName={setName}
                    />
                  }
                />
                <Route
                  exact
                  path="/enterQuery"
                  element={
                    <EnterQuery
                      mainOrigin={origin}
                      mainDestiny={destiny}
                      mainOption={option}
                      mainDate={date}
                      onOption={setOption}
                      onOrigin={setOrigin}
                      onDestiny={setDestiny}
                      user={user}
                      identify={identify}
                      name={name}
                      onDoor={setDoorId}
                      onError={setError}
                    />
                  }
                />
                <Route
                  exact
                  path="/menuOptions"
                  element={<MenuOptions user={user} name={name} />}
                />
                <Route
                  exact
                  path="/shippingLines"
                  element={
                    <ShippingLines
                      origin={origin}
                      destiny={destiny}
                      countryO={countryO}
                      countryD={countryD}
                      onShipping={setShipping}
                      user={user}
                      name={name}
                      doorId={doorId}
                      onError={setError}
                    />
                  }
                />
                <Route
                  exact
                  path="/landRoutes"
                  element={
                    <LandRoutes
                      origin={origin}
                      destiny={destiny}
                      user={user}
                      identify={identify}
                      name={name}
                      doorId={doorId}
                      onError={setError}
                    />
                  }
                />

                <Route
                  exact
                  path="/serviceRelationship"
                  element={
                    <ServiceRelationship
                      user={user}
                      identify={identify}
                      name={name}
                      onError={setError}
                    />
                  }
                />

                <Route
                  exact
                  path="/latestQueries"
                  element={
                    <LatestQueries
                      onOrigin={setOrigin}
                      onDestiny={setDestiny}
                      onDate={setDate}
                      onOption={setOption}
                      user={user}
                      identify={identify}
                      name={name}
                      onDoorId={setDoorId}
                      onError={setError}
                    />
                  }
                />
                <Route
                  exact
                  path="/routeItinerary"
                  element={
                    <RouteItinerary
                      shipping={shipping}
                      user={user}
                      identify={identify}
                      name={name}
                      onError={setError}
                    />
                  }
                />
                <Route
                  exact
                  path="/errorService"
                  element={<ErrorPage error={error} />}
                />
                <Route
                  exact
                  path="/failedQueries"
                  element={
                    <FailedQueries
                      user={user}
                      identify={identify}
                      name={name}
                      onError={setError}
                    />
                  }
                />
                <Route
                  exact
                  path="/routeOption"
                  element={
                    <RouteOption
                      user={user}
                      identify={identify}
                      name={name}
                      doorId={doorId}
                      onError={setError}
                    />
                  }
                />
                <Route
                  exact
                  path="/routeOptionsGraph"
                  element={
                    <RouteOptionsGraph
                      user={user}
                      name={name}
                      onOption={setOption}
                      onOrigin={setOrigin}
                      onDestiny={setDestiny}
                      onDoor={setDoorId}
                    />
                  }
                ></Route>
              </>
            )}
            <Route path="/:pageName" element={<PageNotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </OptionContext>
    </div>
  );
}

function PageNotFound() {
  const params = useParams();
  let message = `"${params.pageName}" page not found`;
  if (
    params.pageName === "menu" ||
    params.pageName === "enterQuery" ||
    params.pageName === "menuOptions" ||
    params.pageName === "shippingLines" ||
    params.pageName === "landRoutes" ||
    params.pageName === "routeOption" ||
    params.pageName === "serviceRelationship" ||
    params.pageName === "routeItinerary" ||
    params.pageName === "latestQueries" ||
    params.pageName === "failedQueries"
  ) {
    message = "You need to be logged in to acces this page";
  }
  return <p>{message}</p>;
}

export default App;
