import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styleDetailInPort } from "../styles/Boxes";
  
  export default function DetailInPort({ openDetail, handleCloseDetail, item, index }) {
    return (
      <div>
        <Modal
          open={openDetail}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleDetailInPort}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              component="h2"
              style={{ textAlign: "center" }}
            >
              <b>
                Detalle <b class="modal-ro-blue">en puerto</b>
              </b>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {index}
            </Typography>
            <Typography id="modal-modal-description">
              <b class="modal-ro-blue" id="modal-destiny-door">
                {item.FCPUERTADESTINO}
              </b>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <p>
                <b>Tiempo de descarga: </b>
                {item.FCTIEMPODESCARGA} hrs con {item.FIPROBABILIDADDESCARGA} % de
                probabilidad de que se cumpla en tiempo
                <br />
                Desviación: +- {item.FCDESVTIEMPODESCARGA} hrs
              </p>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <p>
                <b>Tiempo de pago: </b>
                {item.FCTIEMPOPAGO} hrs con {item.FIPROBABILIDADPAGO} % de
                probabilidad de que se cumpla en tiempo
              </p>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <p>
                <b>Tiempo de revisión: </b>
                {item.FCTIEMPOREVISION} hrs con {item.FCPROBABILIDADSEMAFORO} % de
                probabilidad de revisión
              </p>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <p>
                <b>Tiempo de carga: </b>
                {item.FCTIEMPOCARGA} hrs con {item.FIPROBABILIDADCARGA} % de
                probabilidad de que se cumpla en tiempo
              </p>
            </Typography>
            <Typography id="modal-modal-description">
              <b id="modal-shipping">{item.FCNAVIERA}</b>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <p>
                <b>Porcentaje de confiabilidad: </b>
                {item.FICONFIABILIDAD} %
              </p>
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <button
                className="btn-ro-modal"
                type="button"
                onClick={handleCloseDetail}
              >
                Aceptar
              </button>
            </Typography>
          </Box>
        </Modal>
      </div>
    );
  }