import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {
  LOADER_VALIDATE_DATA,
  LOADER_WAIT_A_MOMENT,
} from "./constants/ConstantsMessages";

export default function Loader() {
  return (
    <div className="container_load">
      <br />
      <div className="text_loader">
        {LOADER_VALIDATE_DATA}
        <br /> <br />
        {LOADER_WAIT_A_MOMENT}
        <br /> <br />
        <CircularProgress size={24} />
      </div>
    </div>
  );
}
