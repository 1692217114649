import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#05aad6",
    color: "white",
    fontFamily: "Monserrat",
    borderRight: "1px solid #57bde6",
    textAlign: "center",
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Monserrat",
    borderRight: "1px solid #57bde6",
  },
}));

const StyledTableCellServiceRelationship = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#05aad6",
    color: "white",
    fontFamily: "Monserrat",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    color: "black",
    fontFamily: "Monserrat",
    borderBottom: "1px solid black",
  },
}));

const StyledTableCellRouteItinerary = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "white",
    color: "#01526f",
    fontFamily: "Monserrat",
    borderRight: "1px solid #57bde6",
    fontSize: "11px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    fontFamily: "Monserrat",
  },
}));

const StyledTableCellLandRoutes = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#05aad6",
    color: "white",
    fontFamily: "Monserrat",
    borderRight: "1px solid #57bde6",
    fontSize: 11,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 11,
    fontFamily: "Monserrat",
    borderRight: "1px solid #57bde6",
  },
}));

export {
  StyledTableCell,
  StyledTableCellServiceRelationship,
  StyledTableCellRouteItinerary,
  StyledTableCellLandRoutes
};
