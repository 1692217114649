import React, { useState, useEffect } from "react";
import "../assets/css/latestQueries.css";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";

import Loader from "./common/Loader";
import { StyledTableCell } from "./common/styles/StyledTableCell";
import { StyledTableRow } from "./common/styles/StyledTableRow";

import { desactivateBrowserButtons } from "./common/functions/noBrowserButtons";
import { ERRORS } from "./common/constants/ConstantsErrors";
import { EMPTY, ERRORSERVICE } from "./common/constants/ConstantsNumbers";

export default function LatestQueries({
  onOrigin,
  onDestiny,
  onDate,
  onOption,
  user,
  identify,
  name,
  onDoorId,
  onError,
}) {
  let navigate = useNavigate();
  const apiLatestQueries = process.env.REACT_APP_API_LATESTQUERIES;
  const key = process.env.REACT_APP_API_KEY;
  var historyObj = window.history;

  //para footer
  const siguiente = (
    <button
      className="right-footer"
      onClick={() => {
        navigate("/menu");
      }}
    >
      Menú principal
    </button>
  );

  useEffect(() => {
    desactivateBrowserButtons(historyObj);
  });

  //post axios service latest queries ---------------------------------------------------------------------
  let [info, setInfo] = useState([]);

  useEffect(() => {
    axios
      .post(apiLatestQueries, {
        usuario: identify,
        api_key: key,
      })
      .then(function (response) {
        if (response.data) {
          let aux = [];
          aux = response.data.sort((a, b) => {
            if (a.FDFECHA < b.FDFECHA) {
              return 1;
            }
            if (a.FDFECHA > b.FDFECHA) {
              return -1;
            }
            return 0;
          });
          setInfo(aux);
        }else{
          onError(ERRORS.Empty);
          navigate("/errorService");
        }
      })
      .catch(function (error) {
        if (error.response.status === ERRORSERVICE) {
          onError(ERRORS.Service);
        } else {
          onError(ERRORS.Generic);
        }
        navigate("/errorService");
      });
  }, [apiLatestQueries, identify, key, navigate, onError]);

  //new query
  const newQuery = (parameter) => (event) => {
    onOrigin(info[parameter].FCPUERTAORIGEN);
    onDestiny(info[parameter].FCPUERTADESTINO);
    let aux = info[parameter].FDFECHA.replace("T", " ");
    onDate(aux);
    onOption(info[parameter].FCOPERACION);
    navigate("/enterQuery");
  };

  //historical query
  const historicalQuery = (parameter) => (event) => {
    onOrigin(info[parameter].FCPUERTAORIGEN);
    onDestiny(info[parameter].FCPUERTADESTINO);
    let aux = info[parameter].FDFECHA.replace("T", " ");
    onDate(aux);
    navigate("/menuOptions");
    onDoorId(info[parameter].FIPUERTAID);
  };

  const formatDate = (dateString) => {
    let aux = dateString.replace("T", " ");
    return aux;
  };

  //return --------------------------------------------------------------------------------------------
  return (
    <>
      {info.length <= EMPTY ? (
        <Loader />
      ) : (
        <>
          <Header black="Últimas" blue="consultas" user={user} name={name} />
          <div className="container1">
            <div className="table_lq">
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 325 }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>ORIGEN</StyledTableCell>
                        <StyledTableCell>DESTINO</StyledTableCell>
                        <StyledTableCell>FECHA</StyledTableCell>
                        <StyledTableCell>
                          NUEVA
                          <br />
                          CONSULTA
                        </StyledTableCell>
                        <StyledTableCell>
                          CONSULTA
                          <br />
                          HISTÓRICA
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {info.map((item, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            {item.FCPUERTAORIGEN}
                          </StyledTableCell>
                          <StyledTableCell>
                            {item.FCPUERTADESTINO}
                          </StyledTableCell>
                          <StyledTableCell>
                            {formatDate(item.FDFECHA)}
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            <button
                              className="btn_new_query"
                              onClick={newQuery(index)}
                            >
                              {">"}
                            </button>
                          </StyledTableCell>
                          <StyledTableCell style={{ textAlign: "center" }}>
                            <button
                              className="btn_h_query"
                              onClick={historicalQuery(index)}
                            >
                              +
                            </button>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          </div>
          <Footer sig={siguiente} />
        </>
      )}
    </>
  );
}
